const actions = {
  CHECK_AUTHORIZATION: "CHECK_AUTHORIZATION",
  // LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGOUT: "LOGOUT",
  LOGIN: "LOGIN",
  // LOGIN_SUCCESS: "LOGIN_SUCCESS",
  // LOGIN_ERROR: "LOGIN_ERROR",
  // CHECK_ROLES: "CHECK_ROLES",
  STORE_TOKEN: "STORE_TOKEN",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  LOGOUTSUCCESS: "LOGOUTSUCCESS",
  PAYMENT_DETAILS:"PAYMENT_DETAILS",
  ADD_PLATFORM: "ADD_PLATFORM",
  CHECKISFACEBOOK: "CHECKISFACEBOOK",
  PREMIUMPAY: "PREMIUMPAY",

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  // checkRoles: (data) => ({
  // 	type: actions.CHECK_ROLES,
  // 	data,
  // }),
  updateUser: (user) => {
    return {
      type: actions.UPDATE_USER,
      user,
    };
  },

  updateUserProfile: (user) => {
    return {
      type: actions.UPDATE_USER_PROFILE,
      user,
    };
  },

  login: (user) => {
    return {
      type: actions.LOGIN,
      user,
    };
  },

  storeToken: (token) => {
    return {
      type: actions.STORE_TOKEN,
      token,
    };
  },

  logout: () => {
    return {
      type: actions.LOGOUT,
    };
  },

  removeToken: () => {
    return {
      type: actions.LOGOUTSUCCESS,
    };
  },
  
  addPlatform: (data) => {
    return {
      type: actions.ADD_PLATFORM,
      data
    }
  },

  paymentDetails: (data) => {
    return {
      type: actions.PAYMENT_DETAILS,
      data
    }
  },
  checkIsFacebook: (data) => {
    return {
      type: actions.CHECKISFACEBOOK,
      data
    }
  },
  checkPremiumPay: (data) => {
    return {
      type: actions.PREMIUMPAY,
      data
    }
  }
};

export default actions;
