import { unHandleError } from "../constants/error";
const responseHelper = (res, showNotification = false) => {
  // console.log(res, res.response);
  if (res && res.message === "Network Error")
    return {
      ...res,
      success: false,
      showNotification: true,
      type: "error",
      message: res.message,
      title: "Error",
    };
  if (showNotification && res.status === 200)
    return {
      ...res,
      success: true,
      showNotification: true,
      type: "success",
      message: res.message,
      title: "Success",
    };
  if (showNotification && res.status === 206)
    return {
      ...res,
      success: false,
      showNotification: true,
      type: "warning",
      message: res.message,
      title: "Warning",
    };
  else if (res.status === 200) return { ...res, success: true };
  else if (res.status === 206) return { ...res, success: false };
  else if (res && (res.status == 401 || res.status === 403))
    return {
      ...res,
      logout: true,
      success: false,
      showNotification: true,
      type: "error",
      message: res.message,
      title: "Error",
    };
  else if (
    (res && res.status === 400 && res.message) ||
    (res && res.status === 500 && res.message)
  )
    return {
      ...res,
      success: false,
      showNotification: true,
      type: "error",
      message: res.message,
      title: "Error",
    };
  else if (
    res.response &&
    res.response.data &&
    (res.response.data.status == 401 || res.response.data.status === 403)
  )
    return {
      ...res.response,
      logout: true,
      success: false,
      showNotification: true,
      type: "error",
      message: res.response.data.message,
      title: "Error",
    };
  else if (
    (res.response &&
      res.response.data &&
      res.response.data.status === 400 &&
      res.response.data.message) ||
    (res.response &&
      res.response.data &&
      res.response.data.status === 404 &&
      res.response.data.message) ||
    (res.response &&
      res.response.data &&
      res.response.data.status === 500 &&
      res.response.data.message)
  )
    return {
      ...res.response,
      success: false,
      showNotification: true,
      type: "error",
      message: res.response.data.message,
      title: "Error",
    };
  else
    return {
      ...res,
      ...unHandleError,
    };
};

const typePayloadHelper = (type, data = null, key = "data") => ({
  type,
  payload: { [key]: data },
});

export { responseHelper, typePayloadHelper };
