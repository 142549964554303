import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { logout, getToken, clearToken } from "../../utility/token";
import { checkAuthAPI, logoutAPI } from "./api";
import actions from "./actions";
import { history } from "../store";

// const fakeApiCall = true; // auth0 or express JWT

// export function* loginRequest() {
// 	yield takeEvery("LOGIN_REQUEST", function* (action) {
// 		if (fakeApiCall) {
// 			yield put({
// 				type: actions.LOGIN_SUCCESS,
// 				profile: action.user.user,
// 			});
// 		} else {
// 			yield put({ type: actions.LOGIN_ERROR });
// 		}
// 	});
// }

// export function* loginSuccess() {
// 	yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
// 		if (payload.profile.jwt_token)
// 			yield localStorage.setItem("token", payload.profile.jwt_token);
// 		else yield localStorage.setItem("token", payload.token);
// 		// yield localStorage.setItem("user", JSON.parse(payload.profile));
// 	});
// }

// export function* loginError() {
// 	yield takeEvery(actions.LOGIN_ERROR, function* () {});
// }

// export function* logout() {
// 	yield takeEvery(actions.LOGOUT, function* () {
// 		clearToken();
// 		// yield put(push("/"));
// 	});
// }

function* checkAuth(action) {
  const { token } = getToken();
  if (token) {
    try {
      const data = yield call(checkAuthAPI, token);
      if (data?.logout) {
        clearToken();
        yield put(actions.logout());
        logout(history);
        history.push("/");
        yield put(push("/"));
      }
      localStorage.setItem("token", data.token);
      yield put(actions.login({ user: data.data }));
    } catch (e) {
      // console.log(e);
    }
  }
}

function* logoutFunc(action) {
  try {
    const data = yield call(logoutAPI);
    // console.log("data", data);
    // if (data?.logout) {
    //   clearToken();
    //   yield put(actions.removeToken());
    //   logout(history);
    //   history.push("/");
    //   yield put(push("/"));
    // }
    yield put(actions.removeToken());
  } catch (e) {}
}

export default function* rootSaga() {
  yield all([takeEvery(actions.CHECK_AUTHORIZATION, checkAuth)]);
  yield all([takeEvery(actions.LOGOUT, logoutFunc)]);
}
