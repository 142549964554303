import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory as createHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../redux/sagas";
import { loadState, saveState } from "./localStorage";
import rootReducer from "./root-reducer";
const history = createHistory({ basename: process.env.REACT_APP_BASE_PATH });
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const persistedState = loadState();

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(...middlewares)
);

store.subscribe(() => {
  saveState(store.getState());
});

sagaMiddleware.run(rootSaga);
export { store, history };
