import styled from "styled-components";

export const HeaderMain = styled.div`
  padding: 15px 25px;
  @media (max-width: 767px) {
    padding: 5px 20px;
  }
  &.leftSidebarMenu {
    .ant-menu {
      margin: 35px 0 0 0;
      @media (max-width: 767px) {
        margin: 18px 0 0 0;
        padding-top: 10px;
        li {
          padding: 8px 0px !important;
          font-size: 14px !important;
        }
      }
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: 1px solid #29faff;
      border-bottom: 1px solid #29faff;
      display: block;
      li {
        float: left;
        width: 100%;
        margin: 0;
        padding: 11px 0px;
        text-transform: uppercase;
        -webkit-transition: all 200ms ease;
        -moz-transition: all 2 00ms ease;
        -ms-transition: all 200ms ease;
        -o-transition: all 2 00ms ease;
        transition: all 200ms ease;
        a {
          -webkit-transition: all 200ms ease;
          -moz-transition: all 2 00ms ease;
          -ms-transition: all 200ms ease;
          -o-transition: all 2 00ms ease;
          transition: all 200ms ease;
        }
        &.ant-menu-item-active,
        &.ant-menu-item-selected {
          a {
            color: #29faff;
          }
        }
      }
    }
  }
  .top-main-logo {
    img {
      max-width: 120px;
      @media (max-width: 767px) {
        max-width: 90px;
      }
    }
  }
  .header-profile-pic {
    width: 20px;
    height: 20px;
    border-radius: 100% !important;
    border: 1px solid #fff;
  }

  width: 100%;
  z-index: 999;
  .ant-menu {
    background: transparent;
    border: 0;
    font-size: 16px;
    color: #fff;
    .ant-menu-item {
      height: auto !important;
      background: transparent !important;
      border: 0 !important;
      position: relative;
      padding: 0;
      &.cta-btn {
        & + .cta-btn {
          margin-left: 10px;
        }
      }
      @media only screen and (max-width: 1200px) {
        margin-left: 22px;
        @media only screen and (max-width: 992px) {
          font-size: 15px;
        }
      }
      margin-right: 0;
      &.cta-btn {
        border: 1px solid #393939 !important;
        &:hover {
        }
      }
      :hover {
        color: #fff;
      }
      &.download-now {
        a {
          border: 1px solid #fff;
          padding: 10px 15px;
          @media only screen and (max-width: 992px) {
            padding: 8px 10px;
          }
        }
        :after {
          height: 100%;
          width: 0%;
          top: 0;
          left: 0;
          bottom: auto;
          transform: none;
          -webkit-transition: all 0.6s ease-in-out;
          -moz-transition: all 0.6s ease-in-out;
          -ms-transition: all 0.6s ease-in-out;
          -o-transition: all 0.6s ease-in-out;
          transition: all 0.6s ease-in-out;
        }
        :hover {
          a {
            color: #be1522;
          }
        }
        :hover:after {
          width: 100% !important;
        }
        &.ant-menu-item-selected {
          :after {
            width: 0;
          }
        }
      }
      a {
        color: #fff;
        font-weight: 500;
        line-height: normal;
        position: relative;
        z-index: 1;
      }
      :after {
        position: absolute;
        left: 50%;

        bottom: -10px;
        background: #fff;
        content: "";
        height: 1px;
        width: 0%;
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
      }
      &.ant-menu-item-selected {
        font-weight: 500;
        color: #fff;
        &:after {
          width: 100%;
          background: ;
        }
      }
      @media only screen and (max-width: 992px) {
        margin-left: 20px;
      }
      @media only screen and (max-width: 767px) {
        margin-left: 0px;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .top-left img {
    }
    @media only screen and (max-width: 767px) {
      .top-left img {
      }
    }
  }
`;

export const Logo = styled.image``;

export const TopHeader = styled.div`
  padding: 10px 0;
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  // background: #141414 !important;
  z-index: 99;
  @media only screen and (max-width: 992px) {
    padding: 10px 0;
    @media only screen and (max-width: 767px) {
      padding: 12px 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .top-right {
    }
  }
  &.fixed {
    // background: #141414 !important;
    padding: 10px 0;
    @media only screen and (max-width: 992px) {
      padding: 10px 0;
      @media only screen and (max-width: 767px) {
        padding: 12px 0;
      }
    }

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    .ant-menu li a {
      color: !important;
    }
    .fixed-logo {
      opacity: 1 !important;
    }
    .default-logo {
      opacity: 0;
    }
    .ant-menu li:after {
      // background: !important;
    }
    .download-now {
      :after {
        background: !important;
      }
      a {
        border-color: !important;
      }
      :hover a {
        color: #fff !important;
      }
    }
    & + .header-progress > div {
    }
  }
  .top-left {
    position: relative;
    margin-right: 0px;
    img {
      @media only screen and (max-width: 992px) {
        @media only screen and (max-width: 992px) {
        }
      }
    }
    .fixed-logo {
      opacity: 0;
    }
    img {
    }
  }
  .top-right {
    .my-profile-menu {
      ul {
        li {
          padding: 10px 12px;
          border-bottom: 1px solid #999999 !important;
          &:last-child {
            border-bottom: 0 !important;
          }
          &:hover {
            background: #000;
            a {
              color: #fff !important;
            }
          }
          a {
            padding: 0;
          }
        }
      }
      .ant-dropdown {
        min-width: 150px !important;
      }
      .default-dropdown {
        margin: 0;
        .ant-btn {
          padding: 0;
          z-index: 999;
          background: transparent;
          color: #fff;
          border: 0;
          text-transform: uppercase;
          font-size: 16px;
          i {
            margin-left: 10px;
          }
        }
      }
      @media (max-width: 1500px) {
        @media (max-width: 1300px) {
          @media (max-width: 1200px) {
          }
        }
      }
      ul {
        margin: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        li {
          display: flex;
          align-items: center;
          float: left;
          width: 100%;
          margin-left: 15px;
          &.border-right {
            position: relative;
            padding-right: 10px;
            &:after {
              position: absolute;
              right: -3px;
              top: 5px;
              content: "";
              height: 60%;
              background: #d1d1d1;
              width: 1px;
            }
          }
          .user-image {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: inline-block;
          }
          a {
            color: #000000;
            font-family: "CambriaBold";
            i {
              font-size: 18px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }
    .ant-menu,
    .leftMenu {
      width: 100%;
      li {
        a {
        }
      }
    }
  }
`;
