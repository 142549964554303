import React, { Component } from "react";
import { FooterMain } from "./footer-style";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  getContactDeatails = () => {};
  render() {
    return (
      <FooterMain className=" footer-section footer-main withSidebar full-width leftSidebarPadding1 sameTransition">
        <div className="wrapper">
          <div>fanify.io</div>
          {/* <div className="full-width footer-top">
            <div className="footer-logo footer-box">
              <img src="https://fanify-public.s3.ap-south-1.amazonaws.com/fanify/images/auth-top-logo.svg" />
            </div>
            <div className="footer-box text-center">
              <div className="footer-icon full-width">
                <i className="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              1 Cuchulainn Terrace <br></br>
              Castletown Rd, Dundalk, Ireland<br></br>
              A91 C1X2
            </div>
            <div className="footer-box text-center">
              <div className="footer-icon full-width">
                <i className="fa fa-phone" aria-hidden="true"></i>
              </div>
              <a href="tel:+4733378901">+353 (0) 42 9353 570</a> <br></br>
            </div>
            <div className="footer-box text-center">
              <div className="footer-icon full-width">
                <i className="fa fa-envelope" aria-hidden="true"></i>
              </div>
              <Link>hello@fanify.io</Link> <br></br>
              <Link>support@fanify.io</Link>
            </div>
            <div className="footer-box footer-menu">
              <ul>
                <li>
                  <Link to={"/info/about-us"}>What we do</Link>
                </li>
                <li>
                  <Link to={"/info/terms-and-conditions"}>
                    Term & Conditions
                  </Link>
                </li>
                <li>
                  <Link to={"/info/privacy-policy"}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </FooterMain>
    );
  }
}
