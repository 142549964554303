import Api from "../../utility/apiHandler";
import { apiConst } from "../../constants/api";
import { getToken } from "../../utility/token";
import { GetToken } from "../../firebaseinit";
import { responseHelper } from "../../utility/response";

const api = new Api();

const checkAuthAPI = async (token) => {
  try {
    const fcmToken = await GetToken();
    const res = await api.post(apiConst.checkAuthorization, {
      data: {
        accessToken: getToken().token,
        notificationToken: fcmToken || null,
        isMobile: window.innerWidth > 577 ? false : true,
      },
    });
    return responseHelper(res);
  } catch (e) {
    // console.log(e);
  }
};

const logoutAPI = async () => {
  try {
    const res = await api.post(apiConst.logoutApi);
    return responseHelper(res);
  } catch (e) {}
};

export { checkAuthAPI, logoutAPI };
