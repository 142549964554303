import actions from "./actions";

const initState = {
  user: null,
  token: null,
  paymentDetails: {
    platforms:[],
    totalAmt: 0,
    facebookData: {},
    isPremiumPay: false,
  }
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN:
      return { ...state, user: action.user.user };
      // case actions.LOGIN_REQUEST:
      // 	return state.set("user", action.user.user);
    // case actions.LOGIN_SUCCESS: {
    // 	if (
    // 		action.token.token &&
    // 		action.token.token != null &&
    // 		action.token.token != undefined
    // 	) {
    // 		return state.set("token", action.token.token);
    // 	} else {
    // 		return state.set("token", action.profile.jwt_token);
    // 	}
    // }

    case actions.STORE_TOKEN: {
      return { ...state, token: action.token.token };
    }
    case actions.PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          totalAmt: action.data,
        }
      };
    }
    case actions.CHECKISFACEBOOK: {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          facebookData: action.data
        }
      }
    }
    case actions.PREMIUMPAY: {
      return {
        ...state,
        paymentDetails: {
          ...state.paymentDetails,
          isPremiumPay: action.data
        }
      }
    }
    case actions.ADD_PLATFORM: {
      return {
        ...state,
        paymentDetails:{
          ...state.paymentDetails,
          platforms: action.data
        }
      }
    }
    case actions.UPDATE_USER: {
      return { ...state, user: action.user };
    }

    case actions.UPDATE_USER_PROFILE: {
      return { ...state, user: { ...state.user, ...action.user } };
    }

    // case actions.CHECK_ROLES:
    // 	return state.set("roles", action.data);
    case actions.LOGOUTSUCCESS:
      return initState;
    default:
      return state;
  }
}

// case actions.LOGIN_SUCCESS:
//       if (action.user.user !== null) {
//         return state.set("user", action.user.user);
//       } else {
//         return state.set("user", action.user.user);
//       }
