import styled from "styled-components";
import { fonts, theme, screen } from "../../../constants/constant";

export const FooterMain = styled.div`
  .footer-top {
    padding: 25px 0;
    @media (max-width: 767px) {
      padding: 35px 0 0px;
    }
  }
  &.footer-main {
    @media (max-width: 992px) {
      font-size: 15px;
    }
    background: #141414;
    line-height: 25px;
    color: #fff;
    font-weight: 500;
    .footer-box {
      .footer-icon {
        margin: 0 0 10px 0;
        font-size: 17px;
      }
      &.footer-menu {
        padding-left: 60px;
        @media (max-width: 1500px) {
          padding-left: 50px;
          @media (max-width: 1400px) {
            padding-left: 20px;
            @media (max-width: 1300px) {
              padding-left: 10px;
              @media (max-width: 767px) {
                padding: 0;
                text-align: center;
              }
            }
          }
        }
      }
      &.footer-logo {
        padding-top: 30px;
        @media (max-width: 992px) {
          width: 100%;
          text-align: center;
          padding: 0px 0 20px 0;
          @media (max-width: 767px) {
            // border-bottom:1px solid #fff;
          }
        }
        img {
          max-width: 120px;
          @media (max-width: 992px) {
            max-width: 100px;
          }
        }
      }
      float: left;
      width: 20%;
      @media (max-width: 992px) {
        width: 25%;
        @media (max-width: 767px) {
          width: 100%;
          margin-bottom: 20px;
          &:last-child {
            margin: 0;
          }
        }
      }
      a {
        color: #fff;
        transition: all 400ms ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
