import React, { Component, Suspense, lazy } from "react";
import AppRouter from "./routing";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
  withRouter,
} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { onMessage } from "firebase/messaging";
import { onMessagingListener } from "./firebaseinit";
import Notifications from "./components/Notification/notification";
import ReactNotification from "./components/Notification/ReactNotification";
import Header from "./components/shared/header/header";
import Footer from "./components/shared/footer/footer";
import { getToken } from "./utility/token";
import { connect } from "react-redux";
import Boot from "./redux/Boot";
import { history } from "./redux/store";
import { Spin } from "antd";

const HomePage = lazy(() => import("./components/homepage/home"));
const LoginPage = lazy(() => import("./components/authentication/login/login"));
const SignUpPage = lazy(() =>
  import("./components/authentication/signup/signup")
);
const ChangePassword = lazy(() =>
  import("./components/authentication/change-password/change-password2")
);
const ForgotPassword = lazy(() =>
  import("./components/authentication/forgot-password/forgot-password")
);
const VideoSteps = lazy(() => import("./components/video-steps/video-step"));
const VideoListings = lazy(() =>
  import("./components/video-listing/video-lists")
);
const UserProfile = lazy(() =>
  import("./components/user-profile/user-profile")
);
const SmartLink = lazy(() => import("./components/smart-link/LinkViewPage"));
const BioLinks = lazy(() => import("./components/bio-link/link-viewPage"));
const Dashboard = lazy(() => import("./components/dashboard-v2/resultsTab"));
const ContentResult = lazy(() =>
  import("./components/content-result/content-result")
);
const StaticPages = lazy(() =>
  import("./components/shared/footer/static-page")
);
const PaymentPage = lazy(() =>
  import("./components/user-profile/paymentPage")
);
const Blog = lazy(() => import("./components/blog/blog"));
const Career = lazy(() => import("./components/careers/career"));
const HowItWorks = lazy(() => import("./components/howItWorks/howItWorks"));
const AboutUs = lazy(() => import("./components/info/aboutus"));
const Pricing = lazy(() => import("./components/info/pricing"));
const PrivacyPolicy = lazy(() => import("./components/info/privacyPolicy"));
const TermsOfUse = lazy(() => import("./components/info/termsOfUse"))

const Verify = lazy(() => import("./components/verify/verify"));
const ReVerify = lazy(() => import("./components/verify/reVerify"))
const FacebookPageVerify = lazy(() =>
  import("./components/verify/facebookPageVerify")
);
const BlogView = lazy(() => import("./components/blog/blog-view"));

const routes = [
  {
    exact: true,
    path: "/",
    component: () => <HomePage />,
  },
  {
    exact: true,
    path: "/login",
    component: () => <LoginPage />,
  },
  {
    exact: true,
    path: "/signup",
    component: () => <SignUpPage />,
  },
  {
    exact: true,
    path: "/change-password/:token",
    component: () => <ChangePassword />,
  },
  {
    exact: true,
    path: "/forgot-password",
    component: () => <ForgotPassword />,
  },
  // {
  //   exact: true,
  //   path: "/smart-link",
  //   component: () => <SmartLink />,
  // },
  {
    exact: true,
    path: "/homepage",
    component: () => <HomePage />,
  },
  {
    exact: true,
    path: "/sl/:id",
    component: () => <SmartLink />,
  },
  {
    exact: true,
    path: "/bl/:id",
    component: () => <BioLinks />,
  },
  {
    exact: false,
    path: "/verify",
    component: () => <Verify />,
  },
  {
    exact: false,
    path: "/reset-verify",
    component: () => <ReVerify />,
  },
  {
    exact: true,
    path: "/facebookPageVerify",
    component: () => <FacebookPageVerify />,
  },
  // {
  //   exact: true,
  //   path: "/dashboard",
  //   component: () => <Dashboard />,
  // },
  // {
  //   exact: true,
  //   path: "/content-result",
  //   component: () => <ContentResult />,
  // },
  { exact: true, path: "/info/:id", component: () => <StaticPages /> },
  {
    exact: true,
    path: "/blog",
    component: () => <Blog />,
  },
  {
    exact: true,
    path: "/blog/:token",
    component: () => <BlogView />,
  },
  {
    exact: true,
    path: "/careers",
    component: () => <Career />,
  },
  {
    exact: true,
    path: "/how-it-works",
    component: () => <HowItWorks />,
  },
  {
    exact: true,
    path: "/aboutus",
    component: () => <AboutUs />,
  },
  {
    exact: true,
    path: "/privacy-policy",
    component: () => <PrivacyPolicy />,
  },
  {
    exact: true,
    path: "/terms-of-use",
    component: () => <TermsOfUse />
  },
  {
    exact: true,
    path: "/pricing",
    component: () => <Pricing />,
  },
  {
    exact: true,
    path: "/payment",
    component: () => <PaymentPage />,
  } 
];

function PrivateRoute({ children, ...rest }) {
  let isLoggedIn = useSelector((state) => state.Auth.token);

  if (!isLoggedIn) isLoggedIn = getToken().token;
  // let isLoggedIn = false;
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      notification: {
        title: "",
        body: "",
      },
      path: null,
    };
  }

  componentDidMount() {
    const demo = this.props.location.pathname.split("/");
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_ANALYTICS_ID,
      {},
      { debug: false, autoConfig: false }
    );
    ReactPixel.pageView();
    ReactPixel.fbq("track", "PageView");
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const unlisten = this.props.history.listen((location) => {
      ReactPixel.pageView();
      ReactPixel.fbq("track", "PageView");
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
    this.setState({
      path: demo[1],
    });
    try {
      onMessagingListener()
        .then((payload) => {
          // console.log("component", payload);
          let notifications = this.state.notification;
          notifications["title"] = payload.notification.title;
          notifications["body"] = payload.notification.body;
          this.setState({
            show: true,
            notification: notifications,
          });
          // console.log("payload", payload);
        })
        .catch((e) => console.log("failed", e));
    } catch (e) {
      // console.log("e", e);
    }
  }

  render() {
    return (
      // <div>
      <Suspense
        className="loader-full"
        fallback={
          <div className="full-width spin-box text-center loader-full ">
            <Spin />
          </div>
        }
      >
        {/* {this.state.show ? (
          <ReactNotification
            title={this.state.notification.title}
            body={this.state.notification.body}
          />
        ) : null}
        <Notifications /> */}
        {(this.props.isLoggedIn ||
          this.props.location.pathname === "/homepage" ||
          this.props.location.pathname === "/") &&
          this.state.path !== "sl" &&
          this.state.path !== "bl" &&
          this.props.location.pathname !== "/verify" &&
          this.props.location.pathname !== "/facebookPageVerify" &&
          this.props.location.pathname !== "/dashboard" &&
          this.props.location.pathname !== "/bio-link" &&
          this.props.location.pathname !== "/bio-link/add" &&
          this.props.location.pathname !== "/bio-link/edit" &&
          this.props.location.pathname !== "/smart-link" &&
          this.props.location.pathname !== "/smart-link/add" &&
          this.props.location.pathname !== "/smart-link/edit/:id" &&
          this.props.location.pathname !== "/user-profile/me" && <Header />}
          
        <Switch>
          {routes.map((element) => (
            <Route
              exact={element.exact === false ? false : true}
              key={element.path}
              path={`${element.path}`}
              component={element.component}
              {...this.props}
            />
          ))}
          <PrivateRoute path="/">
            <AppRouter user={this.props.user} />
          </PrivateRoute>
        </Switch>
        {(this.props.isLoggedIn ||
          this.props.location.pathname === "/homepage" ||
          this.props.location.pathname === "/") &&
          this.state.path !== "sl" &&
          this.state.path !== "bl" &&
          this.props.location.pathname !== "/verify" &&
          this.props.location.pathname !== "/facebookPageVerify" &&
          this.props.location.pathname !== "/dashboard" && 
          this.props.location.pathname !== "/bio-link" &&
          this.props.location.pathname !== "/bio-link/add" &&
          this.props.location.pathname !== "/bio-link/edit" &&
          this.props.location.pathname !== "/smart-link" &&
          this.props.location.pathname !== "/smart-link/add" &&
          this.props.location.pathname !== "/smart-link/edit/:id" &&
          this.props.location.pathname !== "/user-profile/me" }
          
      </Suspense>
      // </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.token,
  user: state.Auth.user,
});

Boot()
  .then(() => App())
  .catch((error) => console.error(error, "Boot Error"));

export default withRouter(connect(mapStateToProps)(App));
