import { Menu } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const MenuList = ({ isLoggedIn}) => {
  return (
    <>
    <div>
        {isLoggedIn ? (
          <Menu>
            <Menu.Item key="/dashboard">
              <Link className="remove-data-hover" to="/dashboard">
                <span className="menuIcon">
                  <img src="images/home.svg" alt="home" />
                </span>
                <span className="menuText">Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/Content" className="not-active">
              <Link className="remove-data-hover" to="/Content">
                <span className="menuIcon">
                  <img src="images/content.svg" alt="Content" />
                </span>
                <span className="menuText">Content</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/bio-link">
              <Link to="/bio-link" className="remove-data-hover">
                <span className="menuIcon">
                  <img src="images/biolink.svg" alt="Bio Link" />
                </span>
                <span className="menuText">Bio Link</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/smart-link">
              <Link to="/smart-link" className="remove-data-hover">
                <span className="menuIcon">
                  <img src="images/smart-link.svg" alt="Smart Link" />
                </span>
                <span className="menuText">Smart Link</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/subscriptions">
              <Link to="/subscriptions" className="remove-data-hover">
                <span className="menuIcon">
                  <img src="images/subscriptions-icon.png" alt="Smart Link" />
                </span>
                <span className="menuText">Subscriptions</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/transactions">
              <Link to="/transactions" className="remove-data-hover">
                <span className="menuIcon">
                  <img src="images/transactions-icon.png" alt="Smart Link" />
                </span>
                <span className="menuText">Transactions</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/achievments" className="not-active">
              <Link to="/achievments" className="remove-data-hover">
                <span className="menuIcon">
                  <img src="images/achievements.svg" alt="Achievments" />
                </span>
                <span className="menuText">Achievements</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/fun-map" className="not-active">
              <Link to="/fun-map" className="remove-data-hover">
                <span className="menuIcon">
                  <img src="images/map.svg" alt="Fan Map" />
                </span>
                <span className="menuText">Fan Map</span>
              </Link>
            </Menu.Item>
          </Menu>
        ) : null}
      </div>
    </>
  )
}

export default MenuList