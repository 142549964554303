import React, { useState } from 'react'
import { useEffect } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import ReactPlayer from 'react-player'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { apiConst } from '../../constants/api'
import Api from '../../utility/apiHandler'
import { responseHelper } from '../../utility/response'
import { logout } from '../../utility/token'
import { openNotificationWithIcon } from '../openNotification/openNotification'
import authActions from "../../redux/auth/actions"

const { paymentDetails } = authActions

const api = new Api()

const Cart = ({showCart, videoData, getTotalDailyBudget, getVideoData}) => {
  const [state, setState] = useState({
    cartDetails: {},
    isPremiumPlan: false,
    headerCtaClick: false,
    closeClick: false,
    premiumBudget: 0,
    dailyBudget: 0,
    isLoading: false
  })
  const history = useHistory()
  const userData = useSelector(state => state.Auth.user)
  const [isVisible, setIsVisible] = useState(false)
  
  const handleVisible = () => { 
    setIsVisible(!isVisible)
  }

  const onClickRemove = async (id) => {
    try {
      setState({...state,
        isLoading: true,
      });
      const response = await api.post(
        apiConst.changeVideoStatus.replace(":id", id),
        { data: { status: "notActive" } }
      );
      const data = responseHelper(response, true);
      if (data?.logout) {
        openNotificationWithIcon(data?.type, data?.title, data?.message);
        logout(history);
        return;
      }
      if (data && data.success) {
        getCart();
        getVideoData();
        openNotificationWithIcon(data?.type, data?.title, data?.message);
        setState({...state, isLoading: false})
      } else {
        openNotificationWithIcon(data?.type, data?.title, data?.message);
      }
    } catch (e) {
      openNotificationWithIcon("error", "error", "Oops Something Went Wrong");
      setState({...state,
        isLoading: false,
      });
    }
  };
  const getCart = async () => {
    try {
      setState({...state,
        isLoading: true,
      });
      const response = await api.get(apiConst.getAllCartVideos);
      const data = responseHelper(response, true);
      if (data?.logout) {
        openNotificationWithIcon(data?.type, data?.title, data?.message);
        logout(history);
        return;
      }
      if (data && data.success) {
        let dailyBudgetTotal = data.data.budget;
        const isPremiumPlan =
          data.data.cart.filter((e) => {
            if (e._id === 1) {
              dailyBudgetTotal = data.data.budget - e.subscription;
              return e._id;
            }
          }).length > 0;
        setState(
          {...state,
            cartDetails: data.data,
            isPremiumPlan,
            dailyBudget: dailyBudgetTotal,
            premiumBudget: Number(data.data.budget) - Number(dailyBudgetTotal),
          },
          );
          getTotalDailyBudget()
        // openNotificationWithIcon(data?.type, data?.title, data?.message);
      } else {
        // openNotificationWithIcon(data?.type, data?.title, data?.message);
      }
    } catch (e) {
      openNotificationWithIcon("error", "error", "Oops Something Went Wrong");
    }
  };
  const updateDailyBudget = async () => {
    try {
      const response = await api.post(apiConst.updateDailyBudget, {
        data: { budget: state?.dailyBudget },
      });
      const data = responseHelper(response, true);

      if (data?.logout) {
        openNotificationWithIcon(data?.type, data?.title, data?.message);
        logout(history);
        return;
      }
      if (data && data.success) {
        openNotificationWithIcon(data?.type, data?.title, data?.message);
        getVideoData()
      }
    } catch (error) {
      console.log(error);
      openNotificationWithIcon(
        "error",
        "error",
        "Oops Something Went Wrong"
      );
    }
  };
  const toggleClass = () => {
    const currentState = state.headerCtaClick;
    setState({...state, headerCtaClick: !currentState });
  };
  const toggleCloseClass = () => {
    const currentState = state.closeClick;
    setState({...state, closeClick: !currentState });
  };

  useEffect(() => { 
    getCart() 
  },[videoData])
  const dispatch = useDispatch()

  useEffect(async () => {
    if(userData?.dailyBudgetSubscriptionId &&
      userData?.premiumSubscriptionId && state.dailyBudget > 0){
        await updateDailyBudget()
    }
  },[userData?.dailyBudgetSubscriptionId, state.dailyBudget > 0])

  const handlePay = (dailyBudget,isPremiumPlan,premiumBudget, cartCount, paymentType) => {
    let finalObj = { dailyBudget, isPremiumPlan, premiumBudget, cartCount, paymentType, facebookData: null }
    dispatch(paymentDetails(finalObj))
  }

  const isOnlyPremiumPlan = state.cartDetails?.cart?.length === 1 && state.cartDetails?.cart?.filter((e) => { return e._id === 1 }).length > 0
  const isPlanFree = state.cartDetails?.cart?.filter((e) => { return e.free === true }).length > 0
  
  return (
    <div
        className={`${
          state.headerCtaClick
            ? "headerCta NewheaderCta full-width ctaClicked"
            : "headerCta NewheaderCta full-width "
        }`}
        onClick={toggleClass}
      >
        <a className="">
          <span className="countParent">
            <span className="count">
              {state.cartDetails &&
                state.cartDetails.cart &&
                state.cartDetails.cart.length}
            </span>
            <img src="images/cart-blue.svg" alt="cart" />
          </span>
          {/* <i className="fa fa-cart-plus" aria-hidden="true"></i> */}
          Cart
        </a>

        <div
          className={`${
            state.closeClick
              ? "cartBox updated closeClicked"
              : "cartBox updated "
          }`}
          onClick={toggleCloseClass}
        >
          <div className="cartTitle full-width">
            <div className="close">Close</div>
            <h5>cart</h5>
          </div>
          <div className="cartBoxHeight full-width">
            <Scrollbars
              className="scrollbarsDiv full-width"
              style={{ height: 290 }}
            >
              {state.cartDetails &&
              state.cartDetails.cart &&
              state.cartDetails.cart.length > 0 ? (
                <>
                  {state.cartDetails.cart.map((element, index) => (
                    <div className="full-width" key={index}>
                      <div className="videoCheckboxClass fullWidth">
                        {element._id == 1 ? (
                          <>
                            <div className="checkBoxVideoTop full-width">
                              {/* <button
                              role="presentation"
                              className="remove-video"
                              value={element}
                              onClick={() => this.onClickRemove(element._id)}
                            >
                              <i
                                role="presentation"
                                className="fa fa-times-circle"
                                aria-hidden="true"
                              ></i>
                            </button> */}
                              <img
                                src={element.img}
                                alt=""
                                className="reactVideo"
                              />
                            </div>
                            <div className="budgetForm">
                              <div className="budgetFormTop">
                                <div className="">Monthly Subscription</div>
                              </div>
                              <div className="budgetFormBottom budgetFormBottomNew">
                                <div>
                                  <div className="bold">
                                    <span
                                      className={element.free ? "red" : "white"}
                                    >
                                      $ {element.subscription}
                                    </span>
                                    {element.free && (
                                      <>
                                        <span>-</span>
                                        <span>$ 0.00</span>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* <div className="budgetFormBottom">
                                <div>
                                  <div className="bold platform">
                                    {element.platform}
                                  </div>
                                  <div className="bold">
                                    daily budget :
                                    <span>$ {element.subscription}</span>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="checkBoxVideoTop full-width">
                              <button
                                role="presentation"
                                className="remove-video"
                                value={element}
                                onClick={() => onClickRemove(element._id)}
                              >
                                {/* <i
                                  role="presentation"
                                  className="fa fa-times-circle"
                                  aria-hidden="true"
                                ></i> */}
                                {/* <i class="fa fa-times" aria-hidden="true"></i> */}
                                <img
                                  src="/images/close_white.svg"
                                  alt="close"
                                />
                              </button>
                              <ReactPlayer
                                className="reactVideo"
                                url={element.url}
                              />
                            </div>
                            <div className="budgetForm">
                              <div className="budgetFormTop">
                                <div className="">{element.videoName}</div>
                              </div>
                              <div className="budgetFormBottom">
                                <div>
                                  <div className="bold platform">
                                    {element.platform}
                                  </div>
                                  <div className="bold">
                                    daily budget :{" "}
                                    <span>$ {element.dailyBudget}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="full-width emptyCartText boldInfo text-center">
                    Cart is empty !
                  </div>
                </>
              )}
            </Scrollbars>
          </div>
          {state.cartDetails &&
           state.cartDetails.cart &&
           state.cartDetails.cart.length > 0 && (
              <div className="full-width cartBoxBottom">
                <div className="full-width subTotalTitle">
                  subtotal: ${state.cartDetails.budget.toFixed(2)}
                </div>
                <div className="full-width">
                  <Link to={`/payment`}>
                  {isOnlyPremiumPlan ? (
                    <button onClick={() => handlePay(state.dailyBudget, state.isPremiumPlan, state.premiumBudget, state.cartDetails.cart, "OnlyPremiumPlan")}>
                    <img className="fa" src="images/cart.svg" alt="Fb" />
                    pay now
                  </button>
                  ): state.isPremiumPlan && state.dailyBudget !== 0 ? (
                    <>
                    {isPlanFree && state.premiumBudget !== 0 ? (
                      <button onClick={() => handlePay(state.dailyBudget, state.isPremiumPlan, state.premiumBudget, state.cartDetails.cart, "DoublePaymentwithFreePremium")}>
                      <img className="fa" src="images/cart.svg" alt="Fb" />
                      pay now
                    </button>
                    ) : <button onClick={() => handlePay(state.dailyBudget, state.isPremiumPlan, state.premiumBudget, state.cartDetails.cart, "DoublePaymentwithPaidPremium")}>
                    <img className="fa" src="images/cart.svg" alt="Fb" />
                    pay now
                  </button> }
                    </>
                  ) : <button onClick={() => handlePay(state.dailyBudget, state.isPremiumPlan, state.premiumBudget, state.cartDetails.cart, "DailyBudgetPayment")}>
                  <img className="fa" src="images/cart.svg" alt="Fb" />
                  pay now
                </button>}
                  </Link>
                </div>
                <div className="full-width orText">
                  <div className="full-width">or</div>
                  <div className="full-width">
                    {/* <a>Continue shopping</a> */}
                    <Link
                      // className="remove-data-hover default-cta"
                      to="/dashboard"
                    >
                      <span>Continue shopping</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
  )
}

export default Cart