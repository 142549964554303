import React, { Component } from "react";
import {NavHashLink} from 'react-router-hash-link'


  const Footer = () => {
    return (

  <footer>

    <div className="container flex1">

      <div className="left_footer">
        <img src="../images/logo.png" alt="Logo"/>
        <p> Grow your fanbase.</p>


      </div>

      <div className="right_footer flex1">

        <div className="box">
          <h3>How It Works</h3>
          <ul>
            <li> <a href="/how-it-works">Grow your fanbase</a></li>
            <li><a href="/smart-link">Smart Link</a></li>
            <li><a href="/bio-link">Bio Link</a></li>
            <li><a href="#">FAQ</a></li>
            </ul>
        </div>


        <div className="box">
          <h3>Pricing</h3>
          <ul>
            <li> <NavHashLink smooth to={`/pricing#gopricing`}>Free</NavHashLink></li>
            <li><NavHashLink smooth to={`/pricing#premium`} >Premium</NavHashLink></li>
            <li><NavHashLink smooth to={`/pricing#enterprise`} >Enterprise</NavHashLink></li>
            </ul>
        </div>



        <div className="box">
          <h3>Company</h3>
          <ul>
            <li> <a href="/aboutus">About us</a></li>
            <li><a href="/careers">Careers</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="terms-service.html">Terms of service</a></li>
            <li><a href="privacy-policy.html">Privacy Policy</a></li>
            </ul>
        </div>


        <div className="box">
          <h3>Contact</h3>
          <ul>
            <li> <a href="#">Technical support</a></li>
            <li><a href="#">Investors</a></li>
            <li><a href="#">Media</a></li>

            </ul>

              <div className="socail">
                <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                <a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a>
              </div>

        </div>


      </div>



    </div>


    <div className="container">
      <div className="bottom_footer">
        <p>©Fanify {new Date().getFullYear()} Fanify.io. All rights reserved.</p>
      </div>
    </div>

  </footer>




    );
  }
export default Footer
