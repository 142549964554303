import React, { Component, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Redirect,
  Switch,
  browserHistory,
} from "react-router-dom";
import { Layout, Menu } from "antd";
import ReactPlayer from "react-player";
import { Scrollbars } from "react-custom-scrollbars";
import SideBar from "./components/shared/sidebar/sidebar";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import "antd/dist/antd.css";
import { logout } from "./utility/token";
import { onLogoutDistroyToken } from "./firebaseinit";
import Cart from "./components/dashboard-v2/cart";

// const HomePage = lazy(() => import("./components/homepage/home"));
// const LoginPage = lazy(() => import("./components/authentication/login/login"));
// const SignUpPage = lazy(() =>
//   import("./components/authentication/signup/signup")
// );
// const ChangePassword = lazy(() =>
//   import("./components/authentication/change-password/change-password")
// );
// const ForgotPassword = lazy(() =>
//   import("./components/authentication/forgot-password/forgot-password")
// );
import Footer from "./components/shared/sidebar-footer/footer";
import Api from "./utility/apiHandler";
import { apiConst } from "./constants/api";
import { responseHelper } from "./utility/response";
const api = new Api();
const PaymentPopup = lazy(() =>
  import("./components/user-profile/payment")
);
const { Header, Sider, Content } = Layout;
const Dashboard = lazy(() => import("./components/dashboard-v2/dashboard"));
const ContentResult = lazy(() =>
  import("./components/content-result/content-result")
);
const VideoSteps = lazy(() => import("./components/video-steps/video-step"));
const VideoListings = lazy(() =>
  import("./components/video-listing/video-lists")
);
const UserProfile = lazy(() =>
  import("./components/user-profile/user-profile")
);
const Transactions = lazy(() => import("./components/user-profile/userTransactions"))
const Subscriptions = lazy(() => import("./components/user-profile/subscriptions"))

const SmartLinkAdd = lazy(() => import("./components/smart-link/Smart-Link"));

const SmartLinkList = lazy(() =>
  import("./components/smart-link/SmartLink-lists")
);
const SmartLinkEdit = lazy(() =>
  import("./components/smart-link/Update-SmartLink")
);

const BioLinkAdd = lazy(() => import("./components/bio-link/Bio-link"));

const BioLinkEdit = lazy(() => import("./components/bio-link/UpdateBioLink"));

const BioLink = lazy(() => import("./components/bio-link/Biolink-lists.js"));

const ChangePassword = lazy(() =>
  import("./components/authentication/change-password/change-password")
);
const Blog = lazy(() => import("./components/blog/blog"));
const Career = lazy(() => import("./components/careers/career"));
const HowItWorks = lazy(() => import("./components/howItWorks/howItWorks"));
const AboutUs = lazy(() => import("./components/info/aboutus"));

const Error = lazy(() => import("./components/shared/Error/404"));

const routes = [
  // privet route aavse
  {
    exact: true,
    path: "/dashboard",
    component: () => <Dashboard />,
    title: "Dashboard",
  },
  {
    exact: true,
    path: "/",
    component: () => <VideoSteps />,
    title: "Dashboard",
  },
  {
    exact: true,
    path: "/video-steps",
    component: () => <VideoSteps />,
    title: "Dashboard",
  },
  {
    exact: true,
    path: "/video-listing",
    component: () => <VideoListings />,
    title: "Dashboard",
  },
  {
    exact: true,
    path: "/user-profile/me",
    component: () => <UserProfile />,
    title: "My Profile",
  },
  {
    exact: true,
    path: "/transactions",
    component: () => <Transactions />,
    title: "User Transactions"
  },
  {
    exact: true,
    path: "/subscriptions",
    component: () => <Subscriptions />,
    title: "Subscriptions"
  },
  // {
  //   exact: true,
  //   path: "/dashboard",
  //   component: () => <Dashboard />,
  // },
  {
    exact: true,
    path: "/content-result/:id",
    component: () => <ContentResult />,
    title: "Dashboard",
  },
  {
    exact: true,
    path: "/smart-link",
    component: () => <SmartLinkList />,
    title: "Smart Link",
  },
  {
    exact: true,
    path: "/smart-link/add",
    component: () => <SmartLinkAdd />,
    title: "Smart-Link",
  },
  {
    exact: true,
    path: "/smart-link/edit/:id",
    component: () => <SmartLinkEdit />,
    title: "Smart-Link",
  },

  {
    exact: true,
    path: "/bio-link/add",
    component: () => <BioLinkAdd />,
    title: "Bio-Link",
  },
  {
    exact: true,
    path: "/bio-link/edit",
    component: () => <BioLinkEdit />,
    title: "Bio-Link",
  },
  {
    exact: true,
    path: "/bio-link",
    component: () => <BioLink />,
    title: "Bio-Link",
  },
  {
    exact: true,
    path: "/change-password",
    component: () => <ChangePassword />,
  },
  {
    exact: true,
    path: "/blog",
    component: () => <Blog />,
  },
  {
    exact: true,
    path: "/careers",
    component: () => <Career />,
  },
  {
    exact: true,
    path: "/how-it-works",
    component: () => <HowItWorks />,
  },
  {
    exact: true,
    path: "/aboutus",
    component: () => <AboutUs />,
  },  
  {
    exact: true,
    path: "/404",
    component: () => <Error />,
  },
];

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      isPaymentModalVisible: false,
      cartDetails: {},
    };
  }

  toggleCollapse = () => {
    const currentState = this.state.collapsed;
    this.setState({ collapsed: !currentState });
  };
  handlePayNow = () => {
    this.setState({
      isPaymentModalVisible: true,
    });
  };
  closePaymentModal = () => {
    this.setState({
      isPaymentModalVisible: false,
    });
  };
  // getCart = async () => {
  //   try {
  //     this.setState({
  //       isLoading: true,
  //     });
  //     const response = await api.get(apiConst.getAllCartVideos);
  //     const data = responseHelper(response, true);
  //     if (data?.logout) {
  //       this.openNotificationWithIcon(data?.type, data?.title, data?.message);
  //       logout(this.props.history);
  //       return;
  //     }
  //     if (data && data.success) {
  //       console.log("data", data);
  //       this.setState({
  //         cartDetails: data.data,
  //       });
  //       this.openNotificationWithIcon(data?.type, data?.title, data?.message);
  //     } else {
  //       this.openNotificationWithIcon(data?.type, data?.title, data?.message);
  //     }
  //   } catch (e) {
  //     this.openNotificationWithIcon(
  //       "error",
  //       "error",
  //       "Oops Something Went Wrong"
  //     );
  //     // this.setState({
  //     //   isLoading: false,
  //     // });
  //   }
  // };
  componentDidMount() {
    // this.getCart();
  }

  render() {
    return (
      <div>
        {this.state.isPaymentModalVisible && (
          <PaymentPopup
            popupVisible={this.state.isPaymentModalVisible}
            closePopup={this.closePaymentModal}
            userData={this.props.user}
            history={this.props.history}
            isLoggedIn={this.props.isLoggedIn}
          />
        )}
        <Suspense className="loader" fallback={<div className=""></div>}>
          <Layout className="main-pages websiteFontFamily full-width">
            <Sider
              className="layout-left sameTransition"
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
            >
              <div className="sqrbx">
                <span className="anticon" onClick={this.toggleCollapse}>
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </span>
              </div>
              <SideBar />
            </Sider>
            <Layout className="site-layout layout-right">
              <div className="layout-right-padding full-width leftSidebarPadding sameTransition">
                <Header
                  className="layout-header leftSidebarPadding sameTransition"
                  style={{
                    padding: 0,
                  }}
                >
                  <div className="layout-header-left">
                    {/* {React.createElement(
                    this.state.collapsed
                      ? MenuUnfoldOutlined
                      : MenuFoldOutlined,
                    {
                      className: "trigger",

                      // onClick: () => this.setState({ collapsed: !collapsed }),
                    }
                  )} */}

                    <h1></h1>
                    <div className="layout-header-left">
                      <div className="top-profile-layout commonDD leftShifted">
                        <div className="profile-circle-new bgimg-main">
                          {/* {console.log("user", this.props.user)} */}
                          <img
                            src={
                              this.props.user.fbUserId
                                ? this.props.user.profilePic
                                : this.props.user.profilePic
                                ? `${process.env.REACT_APP_PROFILE_IMG_PATH}/${this.props.user.profilePic}`
                                : "https://fanify-public.s3.ap-south-1.amazonaws.com/fanify/images/default.jpg"
                            }
                            alt="user"
                          />
                          <span>{this.props.user.firstName}</span>
                          <i
                            className="fa fa-caret-down"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <ul className="menu">
                          <li
                            className="ant-menu-submenu ant-menu-submenu-horizontal"
                            role="menuitem"
                            onClick={() =>
                              this.props.history.push("/user-profile/me")
                            }
                          >
                            <a>My Profile</a>
                          </li>
                          <li
                            role="presentation"
                            onClick={() =>
                              this.props.history.push("/how-it-works")
                            }
                          >
                            <a
                              data-hover="Find out more"
                              className="remove-data-hover2"
                            >
                              <span>Find out more</span>
                            </a>
                          </li>
                          <li
                            role="presentation"
                            onClick={() => this.props.history.push("/blog")}
                          >
                            <a data-hover="Blog" className="remove-data-hover2">
                              <span>Blog</span>
                            </a>
                          </li>
                          <li
                            role="presentation"
                            onClick={() =>
                              this.props.history.push("/aboutus")
                            }
                          >
                            <a
                              className="remove-data-hover2"
                              data-hover="What we do"
                            >
                              <span>What we do</span>
                            </a>
                          </li>
                          <li
                            role="presentation"
                            onClick={() => this.props.history.push("/careers")}
                          >
                            <a
                              className="remove-data-hover2"
                              data-hover="Careers"
                            >
                              <span>Careers</span>
                            </a>
                          </li>
                          <li
                            className="ant-menu-submenu ant-menu-submenu-horizontal"
                            role="menuitem"
                          >
                            <a
                              href="#"
                              role="presentation"
                              onClick={() => {
                                logout(this.props.history);
                                onLogoutDistroyToken();
                              }}
                            >
                              Logout
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* <Cart /> */}
                      {/* <div className="headerCta">
                        <a>
                          <i className="fa fa-cart-plus" aria-hidden="true"></i>
                          Cart
                        </a>
                        
                        <div className="cartBox">
                          <h5>cart</h5>
                          <div className="cartBoxHeight full-width">
                            <Scrollbars
                              className="scrollbarsDiv full-width"
                              style={{ height: 290 }}
                            >
                              {this.state.cartDetails &&
                                this.state.cartDetails.cart &&
                                this.state.cartDetails.cart.map((e) => (
                                  <div className="full-width">
                                    <div className="videoCheckboxClass fullWidth">
                                      <button className="remove-video">
                                        <i
                                          className="fa fa-times-circle"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <div className="checkBoxVideoTop full-width">
                                        <button className="remove-video">
                                          <i
                                            className="fa fa-times-circle"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <ReactPlayer
                                          className="reactVideo"
                                          url={e.url}
                                        />
                                      </div>
                                      <div className="budgetForm">
                                        <div className="budgetFormTop">
                                          <div className="">{e.videoName}</div>
                                        </div>
                                        <div className="budgetFormBottom">
                                          <div>
                                            <div className="bold platform">
                                              {e.platform}
                                            </div>
                                            <div className="bold">
                                              daily budget :{" "}
                                              <span>$ {e.dailyBudget}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </Scrollbars>
                          </div>
                          <div className="full-width cartBoxBottom">
                            <div className="full-width">
                              subtotal: {this.state.cartDetails.budget}
                            </div>
                            <div className="full-width">
                              <button onClick={this.handlePayNow}>
                                <img
                                  className="fa"
                                  src="images/cart.svg"
                                  alt="Fb"
                                />
                                pay now
                              </button>
                            </div>
                            <div className="full-width orText">
                              <div>or</div>
                              <div>
                                <a>+ continue shopping</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Header>
                <Switch>
                  {routes.map((element) => (
                    <Route
                      exact={element.exact === false ? false : true}
                      key={element.path}
                      path={`${element.path}`}
                      component={element.component}
                      {...this.props}
                    />
                  ))}
                  <Redirect from="/" to="/404" />
                </Switch>
              </div>
              <Footer />
            </Layout>
          </Layout>
        </Suspense>
      </div>
    );
  }
}

export default withRouter(AppRouter);
