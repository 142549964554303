const apiConst = {
  login: "front/auth/login",
  signup: "front/auth/signup",
  logoutApi: "front/profile/logout",
  forgotPassword: "front/auth/forgot-password",
  changePassword: "front/profile/change-password",
  resetPassword: "front/auth/reset-password/:token",
  createPost: "front/videos/create",
  likeable: "front/videos/likeables",
  taglines: "front/videos/taglines",
  taglinesBank: "front/videos/taglineBank",
  checkAuthorization: "front/auth/verify-access-token",
  getAllSoundLike: "front/artistProfile/soundAlike",
  getArtistProfile: "front/artistProfile/artistData",
  getAllGenre: "front/artistProfile/genre",
  updateArtistProfile: "front/artistProfile/update",
  getVideoList: "front/videos/get/?page=:page&limit=:limit",
  deleteVideo: "front/videos/delete/:id",
  pauseVideo: "front/videos/pause/:id",
  getEngagement: "front/videos/engagements/:id",
  getVideoListDropDown: "front/dashboard/get-video-dropdown-list",
  dashboard: "front/dashboard/get-video-statistics",
  videoResults: "front/dashboard/get-video-and-graph-details",
  getUserProfile: "front/user/me",
  updateUserProfile: "front/user/update",
  deleteAccount: "front/user/delete",
  getStaticData: "front/staticPage/get",
  checkoutSession: "front/payment/create-session",
  dailyBudget: "front/payment/daily-budget",
  searchSoundLike: "front/artistProfile/searchSoundAlike",
  cancelSubscription: "front/payment/cancel-subscription",
  getBlogList: "front/blogs/lists?page=:page&limit=:limit",
  facebookLink: "front/videos/pageRequest",
  createSmartLink: "front/smartLink/create",
  getSmartLinkList: "front/smartLink/lists?page=:page&limit=:limit",
  getSmartLinkByID: "front/smartLinkPage/get",
  updateSmartLink: "front/smartLink/update",
  userVerification: "front/auth/verify-user/:token",
  resentVerificationLink: "front/auth/resend-verify-email",
  deleteSmartLink: "front/smartLink/delete/:id",
  dashboardActions: "front/dashboard/get-actions",
  updateTagline: "front/artistProfile/updateTaglineBank",
  updateSoundAlike: "front/artistProfile/updateSoundAlike",
  updateGenre: "front/artistProfile/updateGenre",
  createBioLink: "front/bioLink/create",
  getBioLink: "front/bioLink/list",
  updateBioLink: "front/bioLink/update",
  getBlogByID: "front/blogPage/list/:id",
  getBioLinkByID: "front/bioLinkPage/get",
  subscribeEmail: "front/bioLinkPage/subscribe",
  subscribersList:
    "front/bioLink/emails?page=:page&limit=:limit&isExport=:isExport",
  facebookPageVerification: "front/auth/facebookVerify/:token",
  resendFacebookVerificationEMail: "front/videos/resendEmail",
  newVideoCreate: "front/videos/newcreate",
  detailsofVideo: "front/videos/details",
  getVideoDetails: "front/dashboard/get-video-details",
  getAllCartVideos: "front/cart/cartVideo",
  getTotalDailyBudget: "front/dashboard/budget",
  changeVideoStatus: "front/cart/changeStatus/:id",
  updateDailyBudget: "front/cart/updateBudget",
  updateVideoDailyBudget: "front/videos/updateBudget",
  stripeConfig:"front/payment/config",
  stripeCreatePayment:'front/payment/create-payment-intent',
  stripeConfirmPayment:'front/payment/confirm-payment',
  afterFacebookPayment: 'front/videos/facebookafterpayment',
  saveTransaction: 'front/payment/savetransactions',
  getTransactions:  'front/payment/gettransactions',
  connectWithFb: 'front/auth/connect-with-facebook',
  activeSubscriptions: 'front/videos/activesubscription',
  savePremiumOnlyTransaction: 'front/payment/premiumtransactions',
  getUserPremiumTransactionData: 'front/payment/user-premium-transactions',
  checkPremiumUser: 'front/videos/checkpremiumuser',
  saveMetrics: 'front/metrics/save-metrics'
};

export { apiConst };
