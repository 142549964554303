import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { HeaderMain, TopHeader } from './header-style'
import MenuList from "./menuList"

const SideBar = () => {
  const isLoggedIn = useSelector(state => state.Auth.token)
  
  return (
    <HeaderMain className="leftSidebarMenu full-width">
        <div className="top-section new bg-image full-width" id="home">
          <TopHeader className="full-width top-section-inner">
            <div className="top-left full-width top-main-logo">
              <Link to={`/`}>
                <img
                  className=""
                  src="images/fanify-whilte-logo.png"
                  alt="logo"
                ></img>
              </Link>
            </div>
            <div className="top-right full-width">
              <div className="full-width">
                <MenuList isLoggedIn={isLoggedIn} />
              </div>
            </div>
            <div className="sidebarFooter full-width auth-copyrights not-active">
              <div className="upgrade-with-us full-width">
                <a className="full-width">
                  {/* <i className="fa fa-rocket" aria-hidden="true"></i>  */}
                  <img className="fa" src="images/upgrade.svg" alt="upgrade" />
                  upgrade
                </a>
              </div>
              <div className="sidebarFooter bottom full-width">
                <a href="#" className="">
                  cookies
                </a>
                /<a href="/info/privacy-policy">privacy</a>
              </div>
              {/* <div className="">
                <ul>
                  <li>
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      href="https://www.facebook.com/fanifymusic/"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      href="https://www.instagram.com/fanifymusic/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://twitter.com/FanifyMusic"
                    >
                      <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/in/fanify-music-59a76121b/"
                    >
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                ©Fanify {new Date().getFullYear()}, All Rights Reserved.{" "}
              </div> */}
            </div>
          </TopHeader>
        </div>
      </HeaderMain>
  )
}

export default SideBar