import { store } from "../redux/store";

export function clearToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("FCM_TOKEN");
}

export function getToken() {
  try {
    let token = null;
    if (localStorage.getItem("token")) token = localStorage.getItem("token");
    return { token };
  } catch (err) {
    clearToken();
    return {};
  }
}

export function logout(history) {
  store.dispatch({ type: "LOGOUT" });
  clearToken();
  history.push("/");
}
