// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDBiSLsT-lEvBl1U5oY0SLrLakICvCVpdc",
  authDomain: "fanify-41a8a.firebaseapp.com",
  projectId: "fanify-41a8a",
  storageBucket: "fanify-41a8a.appspot.com",
  messagingSenderId: "1045760815399",
  appId: "1:1045760815399:web:6a2a472f4055b105ab7902",
  measurementId: "G-XX01H2RS1F",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const publicKey = process.env.REACT_APP_FIREBASE_KEY;
const messaging = getMessaging(app);

export const GetToken = async () => {
  let currentToken = "";

  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      // setTokenFound(true);
    } else {
      // setTokenFound(false);
    }
  } catch (error) {
    // console.log("Error", error);
  }

  return currentToken;
};

export const onMessagingListener = () => {
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("yooo", payload);
      resolve(payload);
    });
  });
};

export const onLogoutDistroyToken = () => {
  // console.log("in");
  deleteToken(messaging, (payload) => {
    // console.log("deleted", payload);
  });
};
