import React from 'react'
import { useSelector } from 'react-redux'
import { logout } from '../../../utility/token';

const Header = ({ handleLoginModal, handleSignupModal }) => {
    const isLoggedIn = useSelector(state => state.Auth.token)
  return (
    <header>

    <div className="container flex1">

      <div className="logo"><a href="/"><img src="../images/logo.png" alt=""/></a></div>

      {isLoggedIn ? (
        <nav>
        <div className="sb-toggle-right  top_click"> <a href="#">
            <div className="three_line three_line--htx"><span>toggle menu</span> </div>
          </a></div>
        <ul>
          <li><a href="/how-it-works">HOW IT WORKS</a></li>
          <li><a href="/pricing">PRICING</a></li>
          <li><a href="/aboutus"> ABOUT US</a></li>
          <li><a href="#">SUPPORT</a></li>
          <li><a href="/dashboard">DASHBOARD</a></li>
          <li><a className="" onClick={logout}>LOGOUT</a></li>
        </ul>
      </nav>
      ) : (
        <nav>
        <div className="sb-toggle-right  top_click"> <a href="#">
            <div className="three_line three_line--htx"><span>toggle menu</span> </div>
          </a></div>
        <ul>
          <li><a href="/how-it-works">HOW IT WORKS</a></li>
          <li><a href="/pricing">PRICING</a></li>
          <li><a href="/aboutus"> ABOUT US</a></li>
          <li><a href="#">SUPPORT</a></li>
          <li><a className="" onClick={() => handleLoginModal(true)}>LOGIN</a></li>
          <li className=""><a className="" onClick={() => handleSignupModal(true)} class="btn btn-bold">SIGN UP</a></li>
        </ul>
      </nav>
      )}
      

    </div>

  </header>
  )
}

export default Header