const actions = {
    SELECTGENRE: "SELECTGENRE",
    ALLGENRES: "ALLGENRES",
    
    SELECTLIKEABLE: "SELECTLIKEABLE",
    SELECTTAGLINEFINALOPTIONS: "SELECTTAGLINEFINALOPTIONS",
    ALLTAGLINEOPTIONS: "ALLTAGLINEOPTIONS",
    SELECTEDTAGLINEOPTIONS: "SELECTEDTAGLINEOPTIONS",
    DESELECTTAGLINE: "DESELECTTAGLINE",

    SOUNDALIKEDATA: "SOUNDALIKEDATA",
    SELECTEDSOUNDALIKE: "SELECTEDSOUNDALIKE",
    SELECTEDSOUNDALIKEFINALOBJECT: "SELECTEDSOUNDALIKEFINALOBJECT",

    CLEARSOUNDALIKEFINALOBJECT: "CLEARSOUNDALIKEFINALOBJECT",
    CLEARSOUNDALIKEOPTIONS: "CLEARSOUNDALIKEOPTIONS",
    CLEARTAGLINEOPTIONS: "CLEARTAGLINEOPTIONS",
    CLEARTAGLINEFINALOPTIONS: "CLEARTAGLINEFINALOPTIONS",
    CLEARLIKABLEOPTION: "CLEARLIKABLEOPTION",
    CLEARGENREOPTION: "CLEARGENREOPTION",


    SETBUDGET: "SETBUDGET",
    SETISACTIVE: "SETISACTIVE",
    
    CLEARSTATE: "CLEARSTATE",

    ISPREMIUMPAY: "ISPREMIUMPAY",
    ISADDCONTENT: "ISADDCONTENT",

    setIsAddContent: (data) => {
      return {
        type: actions.ISADDCONTENT,
        data
      }
    },

    setIsPremiumPay: (data) => {
      return {
        type: actions.ISPREMIUMPAY,
        data
      }
    },

    setBudget: (data) => {
      return {
        type: actions.SETBUDGET,
        data
      }
    },

    setIsActive: (data) => {
      return {
        type: actions.SETISACTIVE,
        data
      }
    },

    setAllGenres: (data) => {
      return {
        type: actions.ALLGENRES,
        data
      }
    },

    setSoundalikeData: (data) => {
      return {
        type: actions.SOUNDALIKEDATA,
        data
      }
    },

    selectSoundalikes: (data) => {
      return {
        type: actions.SELECTEDSOUNDALIKE,
        data
      }
    },

    selectSoundalikeObject: (data) => {
      return {
        type: actions.SELECTEDSOUNDALIKEFINALOBJECT,
        data
      }
    },
    
    selectGenres: (data) => {
      return {
        type: actions.SELECTGENRE,
        data
      }
    },

    selectLikeable: (data) => {
      return {
        type: actions.SELECTLIKEABLE,
        data
      }
    },

    setTaglineFinalOptions: (data) => {
      return {
        type: actions.SELECTTAGLINEFINALOPTIONS,
        data
      }
    },

    setAllTaglineOptions: (data) => {
      return {
        type: actions.ALLTAGLINEOPTIONS,
        data
      }
    },

    setSelectedTaglineOptions: (data) => {
      return {
        type: actions.SELECTEDTAGLINEOPTIONS,
        data
      }
    },

    deselectTaglineOptions: (data) => {
      return {
        type: actions.DESELECTTAGLINE,
        data
      }
    },

    clearState: () => {
      return {
        type: actions.CLEARSTATE,
      }
    },
  
    clearSoundalikeObject: () => {
      return {
        type: actions.CLEARSOUNDALIKEFINALOBJECT,
      }
    },
  
    clearSoundalikeOptions: () => {
      return {
        type: actions.CLEARSOUNDALIKEOPTIONS,
      }
    },

    clearTaglineOptions: () => {
      return {
        type: actions.CLEARTAGLINEOPTIONS,
      }
    },

    clearTaglineFinalOptions: () => {
      return {
        type: actions.CLEARTAGLINEFINALOPTIONS,
      }
    },
    clearLikeableOption: () => {
      return {
        type: actions.CLEARLIKABLEOPTION,
      }
    },
    clearGenreOption: () => {
      return {
        type: actions.CLEARGENREOPTION,
      }
    },
    


  };
  
  export default actions;
  