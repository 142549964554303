import actions from "./actions";

const initState = {
    soundalike: {
      selectedSoundLikeOptions: [],
      soundAlikeData: [],
      selectedSoundLikeOptionsObject: []
    },
    genre:{
      selectedGenreOptions: [],
      allgenreOptions: []
    },
    tagline: {
      selectedTaglineOptions: [],
      selectedTaglineFinalOptions: [],
      allTaglineOptions: [],
      selectedLikeableOption: [],
    },
    dailyBudget: {
      budget: 1,
      isActive: "true"
    },
    isPremiumPay: false,
    isAddContent: false,
};



export default function dashboardReducer(state = initState, action) {
  switch (action.type) {
    case actions.SETBUDGET:
      return{
        ...state,
          dailyBudget: {
            ...state.dailyBudget,
            budget: action.data
          }
      }
    case actions.SETISACTIVE:
      return {
        ...state,
          dailyBudget: {
            ...state.dailyBudget,
            isActive: action.data
          }
      }
    case actions.ALLGENRES:
      
      return {
        ...state,
          genre:{
            ...state.genre,
            allgenreOptions: action.data
          }
      }

    case actions.SELECTEDSOUNDALIKE:
      return {
        ...state,
          soundalike: {
            ...state.soundalike,
            selectedSoundLikeOptions: action.data
          }
      }
    
    case actions.SELECTEDSOUNDALIKEFINALOBJECT:
      return {
        ...state,
          soundalike: {
            ...state.soundalike,
            selectedSoundLikeOptionsObject: action.data
          }
      }
    
    case actions.SOUNDALIKEDATA:
      return {
        ...state,
          soundalike: {
            ...state.soundalike,
            soundAlikeData: action.data
          }
      }
    case actions.SELECTGENRE:
      return {
        ...state,
          genre:{
            ...state.genre,
            selectedGenreOptions: action.data
          }
      }
    case actions.SELECTLIKEABLE:
      return {
        ...state,
        tagline: {
          ...state.tagline,
          selectedLikeableOption: action.data
        }
      }
    case actions.SELECTTAGLINEFINALOPTIONS:
      return {
        ...state,
        tagline: {
          ...state.tagline,
          selectedTaglineFinalOptions: action.data
        }
      }
      case actions.SELECTEDTAGLINEOPTIONS:
        return {
          ...state,
          tagline: {
            ...state.tagline,
            selectedTaglineOptions: action.data
          }
        }
        case actions.ALLTAGLINEOPTIONS:
          return {
            ...state,
            tagline: {
              ...state.tagline,
              allTaglineOptions: action.data
            }
          }
        case actions.ISPREMIUMPAY:
          return {
            ...state, isPremiumPay: action.data
          }
        case actions.ISADDCONTENT:
          return {
            ...state, isAddContent: action.data
          }

          case actions.CLEARSOUNDALIKEFINALOBJECT:
            return {
              ...state,
                soundalike: {
                  ...state.soundalike,
                  selectedSoundLikeOptionsObject: []
                }
            }
          case actions.CLEARSOUNDALIKEOPTIONS:
            return {
              ...state,
                soundalike: {
                  ...state.soundalike,
                  selectedSoundLikeOptions:[]
                }
            }
          case actions.CLEARTAGLINEOPTIONS:
            return {
              ...state,
                tagline: {
                  ...state.tagline,
                  selectedTaglineOptions:[]
                }
            }

          case actions.CLEARTAGLINEFINALOPTIONS:
            return {
              ...state,
                tagline: {
                  ...state.tagline,
                  selectedTaglineFinalOptions:[]
                }
            }

          case actions.CLEARLIKABLEOPTION:
            return {
              ...state,
                tagline: {
                  ...state.tagline,
                  selectedLikeableOption:[]
                }
            }

          case actions.CLEARGENREOPTION:
            return {
              ...state,
                genre: {
                  ...state.genre,
                  selectedGenreOptions:[]
                }
            }
          
    case actions.CLEARSTATE:
      return initState
    default:
      return state;
  }
}