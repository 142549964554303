import { combineReducers } from 'redux';
import Auth from './auth/reducer';
import dashboard from './dashboard/reducer';
import { routerReducer, routerMiddleware } from "react-router-redux";

export default combineReducers({
  Auth,
  dashboard,
  router:routerReducer
});
