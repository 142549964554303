import axios from "axios";
import { getToken } from "../utility/token";

const METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

const BASEURL = process.env.REACT_APP_API_PATH;

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class Api {
  isLoggedIn = false;
  userData = {};

  constructor() {
    this.baseURL = BASEURL;
    this.getAuthenticationInfo();
  }

  getAuthenticationInfo() {
    const token = getToken().token;
    if (token) {
      this.isLoggedIn = true;
      this.token = token;
    }
  }

  // URL FOR API
  // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
  get(url, data) {
    return new Promise((resolve, reject) => {
      // console.log("url", url);
      this.api(METHOD.GET, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }

  post(url, data) {
    return new Promise((resolve, reject) => {
      // console.log("test", METHOD.POST, url, data);
      this.api(METHOD.POST, url, data)
        .then((response) => {
          // console.log("test inside", response);
          resolve(response);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }

  put(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.PUT, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }

  delete(url, data) {
    return new Promise((resolve, reject) => {
      this.api(METHOD.DELETE, url, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          // console.log(error);
        });
    });
  }

  api(method, url, data) {
    return new Promise((resolve, reject) => {
      let axiosConfig = {};
      axiosConfig.method = method;
      axiosConfig.url = this.baseURL + url;

      axiosConfig.headers = this.setHeaders(data);
      if (data) {
        if (data.params) axiosConfig.params = data.params;

        if (data.data) axiosConfig.data = data.data;
      }

      axios(axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error);

          //DEFAULT ERROR HANDLING
        });
    });
  }

  setHeaders(data) {
    let headers = {};
    headers["accept-language"] = "en";
    if (data) {
      headers["Content-Type"] = "application/json";

      if (data.headers)
        for (var key in data.headers)
          if (data.headers.hasOwnProperty(key))
            headers[key] = data.headers[key];
    }
    if (getToken().token) {
      if (getToken().token != "undefined") {
        headers["authorization"] = "Bearer " + getToken().token;
      }
    }
    return headers;
  }
}

export default Api;
